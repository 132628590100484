<template>
    <div>
        <activix-modal
            size="lg"
            :opened="opened"
            @open="onOpen"
            @closed="onClosed"
            @close="close"
        >
            <template slot="header">
                <h4 class="modal-title">
                    {{
                        !empty(currentCommunication)
                            ? $t('clientCard.editCommunication')
                            : $t('clientCard.addCommunication')
                    }}
                </h4>
            </template>

            <template slot="body">
                <div class="row" v-if="showStatusSelect">
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label>
                            {{ $t('clientCard.result') }}<span class="form-star" v-if="empty(status)"> *</span>
                        </label>

                        <activix-select
                            identifier="value"
                            :options="resultOptions"
                            :disabled="communicationMethod === 'video'"
                            :placeholder="$t('multiselect.selectOption')"
                            v-model="status"
                        >
                            <el-option
                                :label="option.label"
                                :value="option.value"
                                :key="option.label"
                                v-for="option in resultOptions"
                            />
                        </activix-select>
                    </div>
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label for="call-purpose-options">
                            {{ $t('communications.callSummary.purpose.label') }}
                            <span class="form-star" v-if="empty(purposes) && isPurposeRequired"> *</span>
                        </label>

                        <activix-multiselect
                            id="call-purpose-options"
                            identifier="value"
                            label="label"
                            :multiple="true"
                            :select-all="false"
                            :searchable="false"
                            :close-on-select="false"
                            :options="purposeOptions"
                            :placeholder="purposeNoSelectionLabel"
                            :disabled="!isPurposeRequired"
                            :selected="purposes"
                            @update="updatePurposes"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label for="communication_method">
                            {{ $t('clientCard.communicationMethod') }}&nbsp;
                            <span class="form-star" v-if="empty(communicationMethod)">*</span>
                        </label>

                        <activix-tooltip
                            :content="isCrmCommunication ? $t('clientCard.communicationCreatedByCrmDisabledTooltip') : ''"
                        >
                            <activix-select
                                identifier="value"
                                :options="methodOptions"
                                :placeholder="$t('multiselect.selectOption')"
                                :disabled="isCrmCommunication"
                                v-model="communicationMethod"
                            >
                                <el-option
                                    :label="option.label"
                                    :value="option.value"
                                    :key="option.label"
                                    v-for="option in methodOptions"
                                />
                            </activix-select>
                        </activix-tooltip>
                    </div>
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label for="communication_type">
                            {{ $t('clientCard.type') }}&nbsp;
                            <span class="form-star" v-if="empty(communicationType)">*</span>
                        </label>

                        <activix-tooltip
                            :content="isCrmCommunication || communicationTypeIsDisabled ? $t('clientCard.communicationCreatedByCrmDisabledTooltip') : ''"
                        >
                            <activix-select
                                identifier="value"
                                :disabled="isCrmCommunication || communicationTypeIsDisabled"
                                :placeholder="$t('multiselect.selectOption')"
                                :options="typeOptions"
                                v-model="communicationType"
                            >
                                <el-option
                                    :label="option.label"
                                    :value="option.value"
                                    :key="option.label"
                                    v-for="option in typeOptions"
                                />
                            </activix-select>
                        </activix-tooltip>
                    </div>
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label for="date">
                            {{ $t('clientCard.date') }}&nbsp;
                            <span class="form-star" v-if="!communicationDate">*</span>
                        </label>

                        <activix-tooltip
                            :content="isCrmCommunication ? $t('clientCard.communicationCreatedByCrmDisabledTooltip') : ''"
                        >
                            <date-time-picker
                                :disabled="isCrmCommunication"
                                :end-date="currentTime"
                                :start-date="minDate"
                                v-model="communicationDate"
                            />
                        </activix-tooltip>
                    </div>
                    <div class="form-group col-md-3 col-sm-6 col-xs-12">
                        <label>
                            {{ $t('clientCard.user') }}&nbsp;
                            <span class="form-star" v-if="empty(user)">*</span>
                        </label>

                        <activix-tooltip
                            :content="isCrmCommunication && !authUser.isProgrammer() && !isMessenger ? $t('clientCard.communicationCreatedByCrmDisabledTooltip') : ''"
                        >
                            <activix-select
                                :disabled="isCrmCommunication && !authUser.isProgrammer() && !isMessenger"
                                :filterable="true"
                                :options="activeUserOptions"
                                :placeholder="$t('multiselect.selectOption')"
                                v-model="user"
                            >
                                <el-option
                                    :label="option.label"
                                    :value="option.id"
                                    :key="option.id"
                                    v-for="option in activeUserOptions"
                                />
                            </activix-select>
                        </activix-tooltip>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="col-xs-12 form-group"
                        v-if="['phone', 'sms', 'messenger', 'video'].includes(communicationMethod)"
                    >
                        <label for="call-description" class="float-left">
                            {{ descriptionLabel }}&nbsp;
                        </label>

                        <textarea
                            id="call-description"
                            class="form-control"
                            v-model="callDescription"
                        />
                    </div>
                    <div class="col-xs-12 form-group" v-if="communicationMethod == 'email'">
                        <label for="subject_email" class="float-left">
                            {{ $t('email.subject') }}
                            <span class="form-star" v-if="empty(subjectEmail)"> *</span>
                        </label>
                        <input
                            id="subject_email"
                            name="subject_email"
                            class="subject_email form-control"
                            type="text"
                            v-model="subjectEmail"
                        />
                    </div>
                    <div class="col-xs-12 form-group text-left" v-if="communicationMethod == 'email'">
                        <label for="orig_email" class="text-left">
                            {{ $t('clientCard.addOriginalEmail') }}
                            <span class="form-star" v-if="empty(originalEmail)"> *</span>
                            <span :class="{ invisible: !loadingEmail }" class="w-full text-center">
                                <icon :name="$icons.loading" class="spin-inverse | text-sm ml-1" />
                            </span>
                        </label>

                        <div class="form-group" :class="{ invisible: loadingEmail }">
                            <activix-summernote
                                :height="summernote.height"
                                :min-height="summernote.minHeight"
                                :max-height="summernote.maxHeight"
                                @input="updateUserProperties({ 'email_config.signature': $event })"
                                v-model="originalEmail"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="flex items-center">
                    <activix-tooltip :content="$t('general.delete')" v-if="canDeleteCommunication">
                        <icon
                            :name="$icons.trash"
                            class="link-grey-light text-lg | hover:text-red-500"
                            @click="deleteModalOpened = true"
                        />
                    </activix-tooltip>

                    <activix-button class="ml-auto" @click="close">
                        {{ $t('general.cancel') }}
                    </activix-button>

                    <activix-button
                        type="primary"
                        :disabled="saveButtonIsDisabled"
                        :loading="saving"
                        @click="saveCommunication"
                    >
                        {{ !empty(currentCommunication) ? $t('general.save') : $t('general.add') }}
                    </activix-button>
                </div>
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="warning"
            :title="$t('general.areYouSure')"
            :content="$t('clientCard.deleteCommunicationConfirm')"
            :opened.sync="deleteModalOpened"
            @approve="triggerDelete"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useUserCardStore } from '@/store/modules/userCard/store.js';

    import { sort } from '@/utils/index.js';

    import DateTimePicker from '@/components/inputs/DateTimePicker.vue';

    import Purpose from '@/entities/Purpose.js';
    import Service from '@/entities/Service.js';
    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import CommunicationStatus from '@/entities/CommunicationStatus.js';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                activeUserOptions: [],
                currentTime: now().toString(),
                saving: false,
                communicationMethod: 'phone',
                communicationType: 'incoming',
                originalEmail: '',
                subjectEmail: '',
                callDescription: '',
                user: '',
                status: '',
                purposes: [],
                communicationDate: now().toString(),
                loadingEmail: false,
                communicationTypeIsDisabled: false,
                deleteModalOpened: false,
                summernote: {
                    height: null,
                    minHeight: 160,
                    maxHeight: null,
                    focus: true,
                    lang: this.$i18n.locale == 'fr' ? 'fr-FR' : 'en-US',
                },
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['currentCommunication']),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isEdit() {
                return !empty(this.currentCommunication);
            },

            isCrmCommunication() {
                return !empty(this.currentCommunication) && !this.currentCommunication.created_by_user;
            },

            minDate() {
                return this.lead.created_at;
            },

            showStatusSelect() {
                return (
                    (['sms', 'phone'].includes(this.communicationMethod) && this.communicationType == 'outgoing') ||
                    ['messenger'].includes(this.communicationMethod)
                );
            },

            canDeleteCommunication() {
                return (
                    !empty(this.currentCommunication) &&
                    this.currentCommunication.created_by_user &&
                    (this.authUser.id == this.currentCommunication.created_by ||
                        !(this.authUser.isAdvisor() || this.authUser.isBdc()) ||
                        [this.lead.userId, this.lead.bdcUserId].includes(this.authUser.id))
                );
            },

            methodOptions() {
                const options = [
                    {
                        label: this.$t('clientCard.phone'),
                        value: 'phone',
                    },
                    {
                        label: this.$tc('clientCard.email'),
                        value: 'email',
                    },
                    {
                        label: this.$tc('clientCard.sms'),
                        value: 'sms',
                    },
                    {
                        label: this.$tc('clientCard.messenger'),
                        value: 'messenger',
                    },
                ];

                /* eslint-disable-next-line camelcase */
                if (this.currentCommunication?.communication_method_id === CommunicationMethod.VIDEO) {
                    options.push({
                        label: this.$t('clientCard.videoconference'),
                        value: 'video',
                    });
                }

                return sort(options, 'label');
            },

            resultOptions() {
                const options = [
                    CommunicationStatus.ANSWERED,
                    CommunicationStatus.ATTEMPTED,
                ];

                if (this.isOutgoingPhoneCall) {
                    options.push(CommunicationStatus.VOICEMAIL);
                }

                return options.map(option => ({
                    value: option,
                    label: this.$t(`communications.callSummary.result.options.${option}`),
                }));
            },

            isStatusReached() {
                return this.status.toLowerCase().includes('answered');
            },

            isStatusVoicemail() {
                return this.status.toLowerCase().includes('voicemail');
            },

            isPurposeRequired() {
                return this.isOutgoingPhoneCall && (this.isStatusReached || this.isStatusVoicemail);
            },

            purposeOptions() {
                return Purpose.selectPurposeOptions().map(purpose => {
                    return {
                        value: purpose.id,
                        label: purpose.label,
                    };
                });
            },

            purposeNoSelectionLabel() {
                return this.isPurposeRequired
                    ? this.$t('multiselect.selectOption')
                    : this.$t('communications.callSummary.purpose.notRequired');
            },

            typeOptions() {
                return sort(
                    [
                        {
                            label: this.$t('general.incoming'),
                            value: 'incoming',
                        },
                        {
                            label: this.$tc('general.outgoing'),
                            value: 'outgoing',
                        },
                    ],
                    'label',
                );
            },

            descriptionLabel() {
                switch (this.communicationMethod) {
                    case 'phone':
                        return this.$t('clientCard.callDescription');

                    case 'sms':
                        return this.$t('clientCard.smsDescription');

                    case 'messenger':
                        return this.$t('clientCard.messengerDescription');

                    case 'video':
                        return this.$t('clientCard.videoconferenceDescription');
                }

                return '';
            },

            saveButtonIsDisabled() {
                return (
                    !this.communicationDate ||
                    empty(this.communicationMethod) ||
                    empty(this.communicationType) ||
                    (empty(this.user) && this.communicationMethod != 'messenger') ||
                    (this.communicationMethod == 'email' && (!this.originalEmail || !this.subjectEmail)) ||
                    (this.showStatusSelect && !this.status) ||
                    (empty(this.status) || (empty(this.purposes) && this.isPurposeRequired))
                );
            },

            isMessenger() {
                return this.communicationMethod == 'messenger' ||
                    !empty(this.currentCommunication) && this.currentCommunication.communication_method_id == CommunicationMethod.MESSENGER;
            },

            isOutgoingPhoneCall() {
                return this.communicationMethod === 'phone' && this.communicationType === 'outgoing';
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                async handler() {
                    this.activeUserOptions = (await this.contextAccount.getActiveUsers(['first_name', 'last_name']))
                        .map(user => ({
                            id: user.id,
                            label: user.fullName,
                        }));
                },
            },

            currentCommunication(newValue, oldValue) {
                if (typeof newValue === 'undefined') {
                    return;
                }

                if (empty(oldValue) || (!empty(newValue) && typeof newValue.email_body === 'undefined')) {
                    this.fetchCommunication(newValue.id);
                } else if (newValue.id != oldValue.id) {
                    this.updateData(newValue);
                }
            },

            status() {
                this.resetPurposesIfNotRequired();
            },

            communicationMethod() {
                this.status = '';
                this.resetPurposesIfNotRequired();
            },

            communicationType() {
                this.status = '';
                this.resetPurposesIfNotRequired();
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),
            ...mapActions(useGlobalStore, ['communicationCreated', 'communicationDeleted', 'communicationUpdated', 'updateLead', 'appendNewError']),

            triggerDelete() {
                this.$axios
                    .delete(`v1/communications/${this.currentCommunication.id}`)
                    .then(() => {
                        this.communicationDeleted({
                            id: this.currentCommunication.id,
                            lead_id: this.lead.id,
                        });
                        this.close();
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0027',
                            display: true,
                            error,
                        });
                    });
            },

            updatePurposes(purposes) {
                this.purposes = purposes;
            },

            async saveCommunication() {
                if (this.saving) {
                    return;
                }

                this.saving = true;

                const payload = {
                    lead_id: this.lead.id,
                };

                if (this.status) {
                    payload.status = this.status;
                }

                if (this.isPurposeRequired) {
                    payload.purposes = this.purposes?.map(purpose => purpose.value);
                }

                if (
                    !empty(this.callDescription) &&
                    ['phone', 'sms', 'messenger', 'video'].includes(this.communicationMethod)
                ) {
                    payload.description = this.callDescription;
                }

                if (!this.isCrmCommunication) {
                    payload.created_by_user = true;
                    payload.connected = true;

                    switch (this.communicationMethod) {
                        case 'email':
                            payload.communication_method_id = CommunicationMethod.EMAIL;
                            break;

                        case 'sms':
                            payload.communication_method_id = CommunicationMethod.SMS;
                            break;

                        case 'messenger':
                            payload.communication_method_id = CommunicationMethod.MESSENGER;
                            break;

                        case 'video':
                            payload.communication_method_id = CommunicationMethod.VIDEO;
                            break;

                        case 'phone':
                        default:
                            payload.communication_method_id = CommunicationMethod.PHONE;
                    }

                    payload.communication_type_id = this.communicationType == 'outgoing' ? 1 : 2;

                    if (this.communicationMethod == 'messenger') {
                        payload.service_id = Service.FACEBOOK_MESSENGER;
                    } else if (this.communicationMethod == 'email') {
                        payload.service_id = Service.LEAD_XPRESS;
                    } else if (this.communicationType == 'outgoing') {
                        payload.service_id = Service.REVIVE;
                    } else {
                        payload.service_id = Service.TELBOOST;
                    }

                    if (this.originalEmail != '' && this.communicationMethod == 'email') {
                        payload.email_body = this.originalEmail;
                    }

                    if (this.subjectEmail != '' && this.communicationMethod == 'email') {
                        payload.email_subject = this.subjectEmail;
                    }

                    if (this.communicationMethod == 'email') {
                        payload.status = this.communicationType == 'outgoing' ? 'attempted' : 'answered';
                    } else if (this.communicationType == 'incoming') {
                        payload.status = 'answered';
                    }

                    const createdAtSeconds = as_locale(this.lead.created_at, 'created_at').second();

                    payload.created_at = locale_dt(this.communicationDate).addSeconds(createdAtSeconds).toString();
                }

                if (!this.isCrmCommunication || this.isMessenger || this.authUser.isProgrammer()) {
                    payload.user_id = this.user;
                }

                try {
                    if (this.isEdit) {
                        await this.updateCommunication(payload);
                    } else {
                        await this.createCommunication(payload);
                    }

                    this.close();
                } catch (error) {
                    this.saving = false;

                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0028',
                        display: true,
                        error,
                        payload,
                    });
                }
            },

            async createCommunication(payload) {
                const response = await this.$axios.post('v1/communications', payload);

                this.communicationCreated(response.data.data);

                this.updateLead({
                    id: response.data.data.lead.id,
                    newData: response.data.data.lead,
                });
            },

            async updateCommunication(payload) {
                payload.updated_by_user = true;

                const response = await this.$axios.put(`v1/communications/${this.currentCommunication.id}`, payload);

                this.communicationUpdated(response.data.data);
            },

            updateData(communication) {
                if (!empty(communication)) {
                    switch (communication.communication_method_id) {
                        case CommunicationMethod.EMAIL:
                            this.communicationMethod = 'email';
                            break;

                        case CommunicationMethod.SMS:
                            this.communicationMethod = 'sms';
                            break;

                        case CommunicationMethod.MESSENGER:
                            this.communicationMethod = 'messenger';
                            break;

                        case CommunicationMethod.VIDEO:
                            this.communicationMethod = 'video';
                            break;

                        case CommunicationMethod.PHONE:
                        default:
                            this.communicationMethod = 'phone';
                            break;
                    }

                    this.communicationType = communication.communication_type_id === 1 ? 'outgoing' : 'incoming';
                    this.originalEmail = !empty(communication.email_body) ? communication.email_body : '';
                    this.subjectEmail = !empty(communication.email_subject) ? communication.email_subject : '';
                    this.callDescription = !empty(communication.description) ? communication.description : '';
                    this.user = !empty(communication.user_id) ? communication.user_id : '';
                    this.communicationDate = communication.created_at;

                    this.purposes = this.isOutgoingPhoneCall
                        ? this.currentCommunication.purposes?.map(purpose => {
                            return {
                                value: purpose.id,
                                label: purpose.name,
                            };
                        }) ?? []
                        : [];

                    if (this.communicationMethod !== 'video') {
                        if (communication.status === 'answered') {
                            this.status = 'answered';
                        } else if (communication.status === 'voicemail') {
                            this.status = 'voicemail';
                        } else {
                            this.status = 'attempted';
                        }
                    }

                    if (this.communicationMethod === 'email') {
                        this.status = this.communicationType === 'outgoing' ? 'attempted' : 'answered';
                    } else if (this.communicationType === 'incoming') {
                        this.status = 'answered';
                    }

                    if (
                        !communication.email_subject &&
                        !communication.email_body &&
                        communication.communication_method_id === 2 &&
                        !this.loadingEmail
                    ) {
                        this.fetchCommunication(communication.id);
                        this.loadingEmail = true;
                    }
                }
            },

            fetchCommunication(communicationId) {
                if (empty(communicationId)) {
                    return;
                }

                this.$axios
                    .get(`v1/communications/${communicationId}`, {
                        params: {
                            from: 'clientCard',
                        },
                    })
                    .then(response => {
                        useClientCardStore().currentCommunication = response.data.data;
                        this.updateData(response.data.data);
                        this.loadingEmail = false;
                    })
                    .catch(error => {
                        this.loadingEmail = false;

                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0029',
                            display: false,
                            error,
                        });
                    });
            },

            resetPurposesIfNotRequired() {
                if (!this.isPurposeRequired) {
                    this.purposes = [];
                }
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            async onOpen() {
                const user = (await this.contextAccount.getAdvisorUsers(['id'])).find(u => u.id == this.authUser.id);

                if (user) {
                    this.user = user.id;
                }

                this.updateData(this.currentCommunication);
            },

            onClosed() {
                useClientCardStore().currentCommunication = {};

                this.saving = false;
                this.communicationMethod = 'phone';
                this.communicationType = 'incoming';
                this.originalEmail = '';
                this.subjectEmail = '';
                this.callDescription = '';
                this.user = '';
                this.status = '';
                this.purposes = [];
                this.communicationDate = now().toString();
            },
        },
    };
</script>
