import i18n from '@/plugins/vue-i18n.js';
import Enum from './Enum.js';

export default class Purpose extends Enum {
    static get entries() {
        return {
            vehicle_details: 1,
            price_details: 2,
            test_drive: 3,
            appointment: 4,
        };
    }

    static get VEHICLE_DETAILS() {
        return this.entries.vehicle_details;
    }

    static get PRICE_DETAILS() {
        return this.entries.price_details;
    }

    static get TEST_DRIVE() {
        return this.entries.test_drive;
    }

    static get APPOINTMENT() {
        return this.entries.appointment;
    }

    static get purposes() {
        return [
            Purpose.VEHICLE_DETAILS,
            Purpose.PRICE_DETAILS,
            Purpose.TEST_DRIVE,
            Purpose.APPOINTMENT,
        ];
    }

    static selectPurposeOptions(idName = '', labelName = '', sorted = true) {
        const options = this.purposes
            .map(value => {
                return {
                    [idName || 'id']: value,
                    [labelName || 'label']: this.getTranslation(value),
                };
            });

        if (sorted) {
            options.sort(
                (a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']),
            );
        }

        return options;
    }

    static getTranslation(purposeId) {
        return i18n.t(`communications.callSummary.purpose.options.${Purpose.getKey(purposeId)}`);
    }
}
